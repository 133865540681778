import {
  useId, useEffect, useCallback,
} from 'react';
// import { useTheme } from '@mui/styles';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import { map } from './core/MapView';
import { useAttributePreference } from '../common/util/preferences';

const MapMarkers = ({ onClick, markers }) => {
  const id = useId();
  const idStoppage = `${id}-stoppages`;
  // const theme = useTheme();

  // const [stoppageGeofences, setStoppageGeofences] = useState([]);

  const iconScale = useAttributePreference('iconScale', 0.45);

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  //* onMapClick function returns event, which contains lng, lat of any postion on the map.
  const onMapClick = useCallback((event) => {
    if (!event.preventDefault) {
      onClick();
    }
  }, [onClick]);

  //* onMarkerClick function return animated pop up.
  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    //* feature has the first element of features array
    const feature = event.features[0];
    /** this 'if' statement takes onClick function as an argument.
    proceed inside while if statemet is true. */
    if (onClick) {
      const popup = new AnimatedPopup({
        maxHeight: 200,
        maxWidth: '300px',
        boxShadow: '0px 3px 5px rgba(0,0,0,0.5)',
        overflowY: 'auto',
        openingAnimation: {
          duration: 400,
          easing: 'easeInOutQuint',
          transform: 'scale',
        },
        closingAnimation: {
          duration: 15,
          easing: 'easeInBack',
          transform: 'scale',
        },
      });
      fetch(`/api/geofences?stoppageId=${feature.properties.id}`)
        .then((response) => response.json())
        .then((geofences) => {
          const geofenceText = geofences.map((geofence) => `<span style="color: ${geofence.attributes.color}">${geofence.name}</span>`).join(', ');
          popup.setLngLat(feature.geometry.coordinates)
            .setHTML(`<div style="padding-top:10px;"><h6 style="background: #99e6ff; display: block; padding: 7px 20px; text-align: center"><LocationOnIcon style="vertical-align: middle; color: black;" /> ${feature.properties.name}</h6></div>
              <p style="margin: 0;"><b>Latitude</b>: ${feature.properties.lat} <b>Longitude</b>: ${feature.properties.lng} <a href="https://www.google.com/maps?q=${feature.properties.lat},${feature.properties.lng}" target="_blank" rel="noopener noreferrer" style="text-decoration: none;"><i class="material-icons" style="vertical-align: middle">see <b>'${feature.properties.name}'</b> on google map</i></a></p>
              <p style="margin-top: 6px; max-height: 200px; overflow-y: auto;"><b>Routes:</b> ${geofenceText}</p>`)
            .addTo(map.on('move', () => { popup.remove(); }));
        });
    }
  }, [onClick]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    //* layer id is initiated with string 'stops'
    map.addLayer({
      id: idStoppage,
      type: 'symbol',
      source: id,
      minzoom: 7,
      layout: {
        'icon-image': 'default-primary',
        'icon-allow-overlap': true,
        'icon-size': iconScale,
      },
    });

    map.on('mouseenter', idStoppage, onMouseEnter);
    map.on('mouseleave', idStoppage, onMouseLeave);
    map.on('click', idStoppage, onMarkerClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', idStoppage, onMouseEnter);
      map.off('mouseleave', idStoppage, onMouseLeave);
      map.off('click', idStoppage, onMarkerClick);
      map.off('click', onMapClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(idStoppage)) {
        map.removeLayer(idStoppage);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [onMarkerClick]);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: markers.map((stoppage) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [stoppage.longitude, stoppage.latitude],
        },
        //* define stoppage properties here.
        properties: {
          id: stoppage.id,
          name: stoppage.name,
          lat: stoppage.latitude.toFixed(4),
          lng: stoppage.longitude.toFixed(4),
        },
      })),
    });
  }, [markers]);

  return null;
};

export default MapMarkers;
