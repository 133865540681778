import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import CollectionFab from './components/CollectionFab';
// import CollectionActionsFuel from './components/CollectionActionsFuel';
import TableShimmer from '../common/components/TableShimmer';
import FuelCalculation from './FuelReportPage';

const FuelMeasure = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const t = useTranslation();

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/positions');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'settingsFuels']}>
      <div style={{ textAlign: 'center', margin: '15px' }}>
        <b>{t('FuelReport')}</b>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('settingsVehicels')}</TableCell>
            <TableCell>{t('TankPressure')}</TableCell>
            <TableCell>{t('FuelAmmount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <FuelCalculation key={item.id} item={item} />
          )) : (<TableShimmer columns={4} endAction />)}
        </TableBody>
      </Table>
      <CollectionFab editPath="/reports/fuel" />
    </PageLayout>
  );
};

export default FuelMeasure;
