import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
// import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TankPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();
  const validate = () => item && item.name && item.radius && item.distance && item.length && item.height && item.density && item.vertical;

  return (
    <EditItemView
      endpoint="tanks"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsTank']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label="Name"
              />
              <TextField
                value={item.radius || ''}
                onChange={(event) => setItem({ ...item, radius: event.target.value })}
                label={t('TankRadius')}
              />
              <TextField
                value={item.distance || ''}
                onChange={(event) => setItem({ ...item, distance: event.target.value })}
                label={t('TankDistance')}
              />
              <TextField
                value={item.length || ''}
                onChange={(event) => setItem({ ...item, length: event.target.value })}
                label={t('TankLength')}
              />
              <TextField
                value={item.height || ''}
                onChange={(event) => setItem({ ...item, height: event.target.value })}
                label={t('TankHeight')}
              />
              <TextField
                value={item.density || ''}
                onChange={(event) => setItem({ ...item, density: event.target.value })}
                label="Fuel Density"
              />
              {/* <TextField
                value={item.vertical || ''}
                onChange={(event) => setItem({ ...item, vertical: event.target.value })}
                label={t('TankVertical')}
              /> */}
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Verical/Horizontal</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={item.vertical || ''}
                  onChange={(event) => setItem({ ...item, vertical: event.target.value })}
                >
                  <FormControlLabel value="Vertical" control={<Radio />} label="Vertical" />
                  <FormControlLabel value="Horizontal" control={<Radio />} label="Horizontal" />

                </RadioGroup>
              </FormControl>

            </AccordionDetails>
          </Accordion>
          {/* {item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  endpointAll="/api/geofences"
                  endpointLinked={`/api/geofences?tankId=${item.id}`}
                  baseId={item.id}
                  keyBase="tankId"
                  keyLink="geofenceId"
                  label={t('sharedRoutes')}
                />
              </AccordionDetails>
            </Accordion>
          )} */}
        </>
      )}
    </EditItemView>
  );
};

export default TankPage;
