import React, { useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  OutlinedInput,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  tableCellClasses,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import MapMarkers from '../../map/MapMarkers';
import MapCamera from '../../map/MapCamera';
import MapPositions from '../../map/MapPositions';
import { devicesActions } from '../../store';
import MapView from '../../map/core/MapView';
import MapGeofence from '../../map/main/MapGeofence';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  searchBar: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 1,
    zIndex: 1,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
  },
  table: {
    background: 'white',
    cursor: 'pointer',
    borderRadius: 10,
  },
}));

const StoppageSearch = () => {
  const classes = useStyles();
  const filterRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const onClick = useCallback((_, markerId) => {
    dispatch(devicesActions.select(markerId));
  }, [dispatch]);

  const [filterKeyword, setFilterKeyword] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const stoppages = Object.values(useSelector((state) => state.stoppages.items));
  const geofences = Object.values(useSelector((state) => state.geofences.items));
  const positions = Object.values(useSelector((state) => state.positions.items));

  const searchedStoppage = stoppages
    .filter((stoppage) => (stoppage.name.toLowerCase().split(' ').join('')
      .includes(filterKeyword.toLowerCase().split(' ').join(''))))
    .map((stoppage) => stoppage);

  //* onClick clear input
  const handleClear = () => {
    setSelectedItem(null);
    setFilterKeyword('');
  };

  const handleSearch = () => {
    searchedStoppage
      .filter((stoppage) => stoppage.name.toLowerCase().split(' ').join('') === filterKeyword.toLowerCase().split(' ').join(''))
      .map((stoppage) => setSelectedItem(stoppage));
  };

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <div className={classes.searchBar}>
        <OutlinedInput
          //* disable id prop to disable popUp on searchField
          // id="input"
          ref={filterRef}
          //* conditionally setting input value
          value={selectedItem ? selectedItem.name : filterKeyword}
          onChange={(e) => (selectedItem ? setSelectedItem(null) : setFilterKeyword(e.target.value))}
          placeholder={t('searchStoppageMap')}
          startAdornment={(
            <InputAdornment position="start">
              {desktop && (
                <IconButton onClick={() => navigate('/')}>
                  <ArrowBackIcon fontSize="medium" sx={{ marginInlineEnd: 0 }} />
                </IconButton>
              )}
            </InputAdornment>
          )}
          endAdornment={(
            <InputAdornment position="end">
              {selectedItem ? (
                <IconButton onClick={handleClear}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton onClick={handleSearch}>
                  <SearchIcon
                    color={filterKeyword ? 'secondary' : 'null'}
                    fontSize="medium"
                  />
                </IconButton>
              )}
            </InputAdornment>
          )}
          sx={{
            '& fieldset': { border: 'none' },
            boxShadow: 1,
            borderRadius: 2,
            height: 47,
          }}
          size="small"
          fullWidth
        />
        {/* condition for stoppage suggestions */}
        {filterKeyword && !selectedItem && (
          <div className={classes.table}>
            <Table sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 'none' } }}>
              <TableBody>
                {
                  searchedStoppage.map((stoppage) => (
                    <TableRow key={stoppage.id} onClick={() => setSelectedItem(stoppage)} sx={{ ':hover': { backgroundColor: '#eeeeee' } }}>
                      <TableCell>
                        <FmdGoodIcon sx={{ fontSize: 'medium', marginInlineEnd: 0.7, marginBottom: 0.7 }} color="disabled" />
                        {stoppage.name}
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </div>
        )}
      </div>
      <MapView>
        <MapGeofence routes={geofences} />
        <MapMarkers onClick={onClick} />
        {selectedItem ? (
          <MapCamera key={selectedItem.id} longitude={selectedItem.longitude} latitude={selectedItem.latitude} />
        ) : (
          null // TODO set mapCamera to initial position
        )}
        <MapPositions positions={positions} showStatus />
      </MapView>
    </div>
  );
};

export default StoppageSearch;
