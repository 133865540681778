import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, List, ListItemAvatar, ListItemText, ListItemButton, Typography,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import {
  formatAlarm, formatBoolean, formatStatus, getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: (item) => (item.speciality
      ? 'brightness(1) invert(0)'
      : 'brightness(0) invert(1)'),
  },
  listItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  disabledListItem: {
    opacity: 0.5,
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const DeviceRow = ({ data, index, style }) => {
  const dispatch = useDispatch();
  const t = useTranslation();

  const admin = useAdministrator();

  const { itemsA } = data;
  const itemA = itemsA[index];

  const { itemsB } = data;

  const classes = useStyles({ speciality: itemA.speciality, disabled: itemA.disabled });

  const position = useSelector((state) => state.positions.items[itemA.id]);

  const secondaryText = () => {
    if (itemA.status === 'online' || !itemA.lastUpdate) {
      return formatStatus(itemA.status, t);
    }
    return moment(itemA.lastUpdate).fromNow();
  };

  const geofences = Object.values(useSelector((state) => state.geofences.items));

  const routeId = itemsB.filter((itemB) => itemB.vehicleid === itemA.id).map((itemB) => itemB.routeid).pop();
  const routeName = geofences.filter((route) => route.id === routeId).map((route) => route.name);
  const routeColor = geofences.filter((route) => route.id === routeId).map((route) => route.attributes.color);

  return (
    <div style={style}>
      <ListItemButton
        key={itemA.id}
        className={itemA.disabled ? classes.disabledListItem : classes.listItem}
        onClick={() => dispatch(devicesActions.select(itemA.id))}
        disabled={!admin && itemA.disabled}
      >
        <ListItemAvatar>
          <Avatar>
            <img className={classes.icon} src={mapIcons[mapIconKey(itemA.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={itemA.name}
          primaryTypographyProps={{ noWrap: true }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true }}
          classes={{ secondary: classes[getStatusColor(itemA.status)] }}
        />
        {/* routeName & routeColor is being called here. */}
        <Typography noWrap color={routeColor} fontWeight="regular" fontStyle="italic" fontSize="0.9rem">{routeName}</Typography>
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small">
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small">
                  {position.attributes.ignition ? (
                    <FlashOnIcon fontSize="small" className={classes.positive} />
                  ) : (
                    <FlashOffIcon fontSize="small" className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

const DevicesList = ({ devices, tripEntries }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffectAsync(async () => {
    const resDevice = await fetch('/api/devices');
    if (resDevice.ok) {
      dispatch(devicesActions.refresh(await resDevice.json()));
    } else {
      throw Error(await resDevice.text());
    }
  }, []);

  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <List disablePadding>
          <FixedSizeList
            width={width}
            height={height}
            itemCount={devices.length}
            itemData={{ itemsA: devices, itemsB: tripEntries }}
            itemSize={72}
            overscanCount={10}
            innerRef={listInnerEl}
          >
            {DeviceRow}
          </FixedSizeList>
        </List>
      )}
    </AutoSizer>
  );
};

export default DevicesList;
