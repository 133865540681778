//* new state 'stoppages' is being created here.
import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'stoppages',
  initialState: {
    items: {},
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => (state.items[item.id] = item));
    },
    update(state, action) {
      action.payload.forEach((item) => (state.items[item.id] = item));
    },
  },
});

export { actions as stoppagesActions };
export { reducer as stoppagesReducer };
