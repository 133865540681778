import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import MainPage from './main/MainPage';
import RouteReportPage from './reports/RouteReportPage';
import ServerPage from './settings/ServerPage';
import UsersPage from './settings/UsersPage';
import DevicePage from './settings/DevicePage';
import UserPage from './settings/UserPage';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/GroupsPage';
import GroupPage from './settings/GroupPage';
import StoppagesPage from './settings/stoppages/StoppagesPage';
import StoppagePage from './settings/stoppages/StoppagePage';
import StoppageSearch from './settings/stoppages/StoppageSearch';
import SchedulesPage from './settings/SchedulesPage';
import ScheduleEntryPage from './settings/ScheduleEntryPage';
import SchedulePage from './settings/SchedulePage';
import Schedules from './settings/Schedules';
import PositionPage from './other/PositionPage';
import NetworkPage from './other/NetworkPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './other/ReplayPage';
import TripReportPage from './reports/TripReportPage';
import StopReportPage from './reports/StopReportPage';
import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';
import LogBookPage from './reports/LogBooksPage';
import LogEntry from './reports/LogEntry';
import Entries from './settings/Entries';
import VehicleLogsPage from './reports/VehicleLogsPage';
import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import ScheduleMaintenance from './settings/ScheduleMaintenance';
import HelpersPage from './settings/HelpersPage';
import HelperPage from './settings/HelperPage';
import CalendarsPage from './settings/CalendarsPage';
import CalendarPage from './settings/CalendarPage';
import ComputedAttributesPage from './settings/ComputedAttributesPage';
import ComputedAttributePage from './settings/ComputedAttributePage';
import MaintenancesPage from './settings/MaintenancesPage';
import MaintenancePage from './settings/MaintenancePage';
import CommandsPage from './settings/CommandsPage';
import CommandPage from './settings/CommandPage';
import StatisticsPage from './reports/StatisticsPage';
import LoginPage from './login/LoginPage';
import RegisterPage from './login/RegisterPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import GeofencesPage from './other/GeofencesPage';
import GeofencePage from './settings/GeofencePage';
import useQuery from './common/util/useQuery';
import FuelsMemoPage from './reports/FuelsMemoPage';
import FuelPage from './reports/FuelPage';
import TanksPage from './settings/TanksPage';
import TankPage from './settings/TankPage';
import { useEffectAsync } from './reactHelper';
import { devicesActions } from './store';
import EventPage from './other/EventPage';
import PreferencesPage from './settings/PreferencesPage';
import EntryAssign from './settings/EntryAssign';
import AccumulatorsPage from './settings/AccumulatorsPage';
import CommandSendPage from './settings/CommandSendPage';
import App from './App';
import ChangeServerPage from './other/ChangeServerPage';
import FuelMeasure from './reports/FuelMeasure';
import DevicesPage from './settings/DevicesPage';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirectsHandled, setRedirectsHandled] = useState(false);

  const query = useQuery();

  useEffectAsync(async () => {
    if (query.get('token')) {
      const token = query.get('token');
      await fetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate('/');
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await fetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.select(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/');
    } else if (query.get('eventId')) {
      const eventId = parseInt(query.get('eventId'), 10);
      navigate(`/event/${eventId}`);
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return (<LinearProgress />);
  }
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/change-server" element={<ChangeServerPage />} />
      <Route path="/" element={<App />}>
        <Route index element={<MainPage />} />

        <Route path="position/:id" element={<PositionPage />} />
        <Route path="network/:positionId" element={<NetworkPage />} />
        <Route path="event/:id" element={<EventPage />} />
        <Route path="replay" element={<ReplayPage />} />
        <Route path="geofences" element={<GeofencesPage />} />

        <Route path="stoppageSearch" element={<StoppageSearch />} />
        <Route path="schedules" element={<Schedules />} />

        <Route path="settings">
          <Route path="accumulators/:deviceId" element={<AccumulatorsPage />} />
          <Route path="calendars" element={<CalendarsPage />} />
          <Route path="calendar/:id" element={<CalendarPage />} />
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="commands" element={<CommandsPage />} />
          <Route path="command/:id" element={<CommandPage />} />
          <Route path="command" element={<CommandPage />} />
          <Route path="command-send/:deviceId" element={<CommandSendPage />} />
          <Route path="attributes" element={<ComputedAttributesPage />} />
          <Route path="attribute/:id" element={<ComputedAttributePage />} />
          <Route path="attribute" element={<ComputedAttributePage />} />
          <Route path="devices" element={<DevicesPage />} />
          <Route path="device/:id" element={<DevicePage />} />
          <Route path="device" element={<DevicePage />} />
          <Route path="drivers" element={<DriversPage />} />
          <Route path="driver/:id" element={<DriverPage />} />
          <Route path="driver" element={<DriverPage />} />
          <Route path="helpers" element={<HelpersPage />} />
          <Route path="Entries" element={<Entries />} />
          <Route path="helper/:id" element={<HelperPage />} />
          <Route path="helper" element={<HelperPage />} />
          <Route path="EntryAssign" element={<EntryAssign />} />
          <Route path="geofence/:id" element={<GeofencePage />} />
          <Route path="geofence" element={<GeofencePage />} />
          <Route path="groups" element={<GroupsPage />} />
          <Route path="group/:id" element={<GroupPage />} />
          <Route path="stoppages" element={<StoppagesPage />} />
          <Route path="stoppage/:id" element={<StoppagePage />} />
          <Route path="stoppage" element={<StoppagePage />} />
          <Route path="schedules" element={<SchedulesPage />} />
          <Route path="scheduleEntry/:id" element={<ScheduleEntryPage />} />
          <Route path="scheduleEntry" element={<ScheduleEntryPage />} />
          <Route path="schedule/:id" element={<SchedulePage />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="scheduleMaintenance" element={<ScheduleMaintenance />} />
          <Route path="group" element={<GroupPage />} />
          <Route path="maintenances" element={<MaintenancesPage />} />
          <Route path="maintenance/:id" element={<MaintenancePage />} />
          <Route path="maintenance" element={<MaintenancePage />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="notification/:id" element={<NotificationPage />} />
          <Route path="notification" element={<NotificationPage />} />
          <Route path="preferences" element={<PreferencesPage />} />
          <Route path="server" element={<ServerPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="user/:id" element={<UserPage />} />
          <Route path="user" element={<UserPage />} />
          <Route path="tanks" element={<TanksPage />} />
          <Route path="tank/:id" element={<TankPage />} />
          <Route path="tank" element={<TankPage />} />
        </Route>

        <Route path="reports">
          <Route path="chart" element={<ChartReportPage />} />
          <Route path="event" element={<EventReportPage />} />
          <Route path="route" element={<RouteReportPage />} />
          <Route path="logbooks" element={<LogBookPage />} />
          <Route path="LogEntry" element={<LogEntry />} />
          <Route path="logbooks/vehicle" element={<VehicleLogsPage />} />
          <Route path="LogEntry/:id" element={<LogEntry />} />
          <Route path="statistics" element={<StatisticsPage />} />
          <Route path="stop" element={<StopReportPage />} />
          <Route path="summary" element={<SummaryReportPage />} />
          <Route path="trip" element={<TripReportPage />} />
          <Route path="fuels" element={<FuelsMemoPage />} />
          <Route path="fuel/:id" element={<FuelPage />} />
          <Route path="fuel" element={<FuelPage />} />
          <Route path="FuelMeasure" element={<FuelMeasure />} />
          <Route path="FuelMeasure/:id" element={<FuelMeasure />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Navigation;
