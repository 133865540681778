import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Toolbar,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge,
  Typography,
  Switch,
  FormControlLabel,
  FormGroup,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Avatar,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/ViewList';
import SortIcon from '@mui/icons-material/Sort';
import { useDispatch, useSelector } from 'react-redux';
import Item from '../settings/stoppages/Item';
import DevicesList from './DevicesList';
import MapMarkers from '../map/MapMarkers';
import MapView from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/main/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
import BottomMenu from '../common/components/BottomMenu';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
import StatusCard from './StatusCard';
import { devicesActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import usePersistedState from '../common/util/usePersistedState';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import { useAdministrator, useDeviceReadonly } from '../common/util/permissions';
import MapPositions from '../map/MapPositions';
import MapDirection from '../map/MapDirection';
import MapOverlay from '../map/overlay/MapOverlay';
import MapCameraMain from '../map/MapCameraMain';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import MapNotification from '../map/notification/MapNotification';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import EventsDrawer from './EventsDrawer';
import useFeatures from '../common/util/useFeatures';
import SearchSwitch from '../common/theme/searchSwitch';
import timeFormatter from '../common/util/timeFormatter';
import { joiner } from '../common/util/stringUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  list: {
    maxHeight: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 3,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: theme.dimensions.bottomBarHeight,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  listItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-360vw)',
    },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  statusCard: {
    position: 'fixed',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  bottomMenu: {
    position: 'fixed',
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    zIndex: 4,
    width: theme.dimensions.drawerWidthDesktop,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  switch: {
    paddingTop: theme.spacing(2),
  },
}));

const MainPage = ({ style }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();
  const listInnerEl = useRef(null);
  const admin = useAdministrator();

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  const deviceReadonly = useDeviceReadonly();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  //* getting system time
  const date = new Date();
  const sysTime = timeFormatter(date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false }));

  const features = useFeatures();

  const [mapOnSelect] = usePersistedState('mapOnSelect', false);

  const [mapLiveRoutes] = usePersistedState('mapLiveRoutes', false);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.positions.items);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

  const geofences = useSelector((state) => state.geofences.items);
  const stoppages = useSelector((state) => state.stoppages.items);
  const users = useSelector((state) => state.users.items);
  const devices = useSelector((state) => state.devices.items);
  const schedules = useSelector((state) => state.schedules.items);

  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filterUser, setFilterUser] = useState('');
  const [filterKeyword, setFilterKeyword] = useState('');
  const [filterStatuses, setFilterStatuses] = useState('');
  const [filterKeywordStoppage, setFilterKeywordStoppage] = useState('');
  const [filterGeofences, setFilterGeofences] = useState([]);
  const [filteredGeofences, setFilteredGeofences] = useState([]);
  //* useState used for filtering geofences by stoppage
  const [stoppageGeofences, setStoppageGeofences] = useState([]);
  //* useState used for filtering stoppages by geofences
  const [geofenceStoppages, setGeofenceStoppaes] = useState([]);
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);
  //* searchBar state defination
  const [stoppageSearch, setStoppageSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  //* useState for route filter under stoppage search
  const [filteredStoppages, setFilteredStoppages] = useState([]);
  //* useState for stoppage filter under multiple route selection

  const [userGeofences, setUserGeofences] = useState([]);

  const filterRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [devicesOpen, setDevicesOpen] = useState(false);
  const [eventsOpen, setEventsOpen] = useState(false);

  const [hideStoppages, setHideStoppages] = useState(false);
  const [hideDevices, setHideDevices] = useState(false);

  const [activeScheduleId, setActiveScheduleId] = useState();
  const [activeEntries, setActiveEntries] = useState([]);
  const [allDevices, setAllDevices] = useState(false);

  const [tripEntries, setTripEntries] = useState([]);
  const [tripUserDeviceIds, setTripUserDeviceIds] = useState([]);
  const [tripUserDevices, setTripUserDevices] = useState([]);
  const [tripDevcieIds, setTripDeviceIds] = useState([]);
  const [tripDevices, setTripDevices] = useState([]);
  const [tripName, setTripName] = useState();
  const [userDevices, setUserDevices] = useState([]);
  // const [tripRouteIds, setTripRouteIds] = useState([]);

  const eventHandler = useCallback(() => setEventsOpen(true), [setEventsOpen]);
  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  useEffect(() => setDevicesOpen(desktop), [desktop]);

  //* on Stoppage click setDeviceOpen false
  useEffect(() => {
    if (!desktop && mapOnSelect && (selectedDeviceId || selectedItem)) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId, selectedItem]);

  const onClick = useCallback((_, markerId) => {
    dispatch(devicesActions.select(markerId));
  }, [dispatch]);

  const handleClear = () => {
    setSelectedItem(false);
    setFilterKeywordStoppage('');
    setFilteredDevices(allDevices ? Object.values(devices) : tripDevices);
  };

  const stoppageHider = () => (hideStoppages ? setHideStoppages(false) : setHideStoppages(true));
  const deviceHider = () => (hideDevices ? setHideDevices(false) : setHideDevices(true));
  const showAllDevices = () => (allDevices ? setAllDevices(false) : setAllDevices(true));

  const switchDeviceSearch = () => {
    setStoppageSearch(false);
    setFilterGeofences([]);
  };

  const switchStoppageSearch = () => {
    setStoppageSearch(true);
  };

  const searchedS = Object.values(stoppages)
    .filter((stoppage) => (joiner(stoppage.name)
      .includes(joiner(filterKeywordStoppage))))
    .map((stoppage) => stoppage);

  const searcher = () => (stoppageSearch ? switchDeviceSearch() : switchStoppageSearch());

  const getLatestGeofenceId = (deviceId) => tripEntries.filter((entry) => entry.vehicleid === deviceId).map((entry) => entry.routeid).pop();

  //* reading vehicleIds from active schedules
  useEffect(() => {
    Object.values(schedules)
      .filter((schedule) => (schedule.active_status === 'True'))
      .map((schedule) => setActiveScheduleId(schedule.id));

    if (activeScheduleId !== undefined) {
      fetch(`/api/entries?scheduleId=${activeScheduleId}`)
        .then((response) => response.json())
        .then((entries) => setActiveEntries(entries));
    }
  }, [schedules, activeScheduleId]);

  let timeDuration = 235959;
  let entryId = 0;
  let duration = 0;

  for (let i = 0; i < activeEntries.length; i += 1) {
    duration = timeFormatter(activeEntries[i].departure_time) - sysTime;

    if (timeDuration > duration && duration > 0) {
      timeDuration = duration;
      entryId = activeEntries[i].id;
    } else if (timeFormatter(activeEntries[i].departure_time) <= sysTime && sysTime <= timeFormatter(activeEntries[i].arrival_time)) {
      entryId = activeEntries[i].id;
    }
  }
  //* cross-matching system time with scehdule

  useEffect(() => {
    activeEntries.filter((entry) => entry.id === entryId).map((entry) => setTripName(entry.trip_name));

    setTripEntries(activeEntries.filter((entries) => entries.trip_name === tripName));
    setTripDeviceIds(activeEntries.filter((entries) => entries.trip_name === tripName).map((entry) => entry.vehicleid));
    // setTripRouteIds(activeEntries.filter((entries) => entries.trip_name === tripName).map((entry) => entry.routeid));
  }, [activeEntries, tripName]);

  useEffect(() => {
    //* filtering vehicles based on schedule's vehicles & device speciality
    const filtered = Object.values(devices)
      .filter((device) => tripDevcieIds.includes(device.id) || device.speciality === true);

    setTripDevices(filtered);
  }, [devices, tripDevcieIds]);

  useEffect(() => {
    fetch(`/api/devices?userId=${filterUser}`)
      .then((response) => response.json())
      .then((devices) => setUserDevices(devices));
  }, [filterUser, devicesOpen]);

  //* fetch geofences only when selectedItem is true
  useEffect(() => {
    if (selectedItem) {
      fetch(`/api/geofences?stoppageId=${selectedItem.id}`)
        .then((response) => response.json())
        .then((geofences) => setStoppageGeofences(geofences));
    }
  }, [selectedItem]);

  useEffect(() => {
    Promise.all(filterGeofences.map((geofenceId) => (
      fetch(`/api/stoppages?geofenceId=${geofenceId}`)
        .then((response) => response.json()))))
      .then((stoppages) => stoppages.reduce((stoppages, item) => ([...stoppages, ...item]), []))
      .then((stoppages) => setGeofenceStoppaes(stoppages.map((stoppage) => stoppage.id)));
  }, [filterGeofences, devicesOpen]);

  useEffect(() => {
    fetch(`/api/devices?userId=${filterUser}`)
      .then((response) => response.json())
      .then((devices) => setTripUserDeviceIds([...devices
        .filter((device) => device.speciality === true)
        .map((device) => device.id), ...tripEntries
        .filter((entry) => entry.user_type === filterUser)
        .map((entry) => entry.vehicleid)]));
  }, [filterUser, tripEntries, devicesOpen]);

  useEffect(() => {
    const filtered = tripDevices.filter((device) => tripUserDeviceIds.includes(device.id));

    setTripUserDevices(filtered);
  }, [tripDevices, tripUserDeviceIds]);

  useEffect(() => {
    fetch(`/api/geofences?userId=${filterUser}`)
      .then((response) => response.json())
      .then((geofences) => setUserGeofences(geofences));
  }, [filterUser, devicesOpen]);

  //* for sortIcon
  const LocationOnIconColor = stoppageSearch
    ? (!filterKeywordStoppage.length ? 'tertiary' : filterKeywordStoppage && searchedS.length ? 'success' : 'error')
    : 'disabled';
  const DirectionsBusColor = stoppageSearch
    ? 'disabled'
    : (!filterKeyword.length ? 'tertiary' : filterKeyword && filteredDevices.length ? 'success' : 'error');
  const searchDisablity = Boolean(filterKeywordStoppage.length || filterKeyword.length || filterGeofences.length || filterUser || hideStoppages || hideDevices);

  //! useEffect for devices
  useEffect(() => {
    const filtered = selectedItem
      ? tripDevices.filter((device) => !stoppageGeofences.length || stoppageGeofences
        .map((geofence) => geofence.id).includes(getLatestGeofenceId(device.id)))
      : (filterUser ? allDevices ? userDevices : tripUserDevices : allDevices ? Object.values(devices) : tripDevices)
        .filter((device) => !filterStatuses.length || filterStatuses.includes(device.status))
        .filter((device) => !filterGeofences.length || filterGeofences
          .map((geofenceId) => geofenceId).includes(getLatestGeofenceId(device.id)))
        .filter((device) => joiner(`${device.name} ${device.uniqueId}`)
          .includes(joiner(filterKeyword)));

    setFilteredDevices(filtered.sort((a, b) => a.name.localeCompare(b.name)));
  }, [tripDevices, devices, allDevices, filterUser, userDevices, tripUserDevices, filterStatuses, filterGeofences, filterKeyword, joiner, selectedItem, stoppageGeofences]);
  useEffect(() => {
    setFilteredPositions(
      filterMap
        ? filteredDevices
          .filter((device) => positions[device.id])
          .map((device) => positions[device.id])
        : Object.values(positions)
          .filter((position) => filteredDevices.some((device) => device.id === position.deviceId)),
    );
  }, [filteredDevices, positions, filterMap]);

  //! useEffect for stoppage
  useEffect(() => {
    //* all filtered stoppages by multiple route selection belongs to 'filteredS'
    const filteredS = !stoppageSearch
      ? filterGeofences.length && !geofenceStoppages.length ? []
        : Object.values(stoppages).filter((stoppage) => !geofenceStoppages.length || geofenceStoppages.includes(stoppage.id))
      : Object.values(stoppages).filter((stoppage) => stoppage.id === selectedItem.id);

    setFilteredStoppages(filteredS);
  }, [devices, geofences, stoppages, filterKeywordStoppage, geofenceStoppages]);

  //! useEffect for geofences (device search)
  useEffect(() => {
    //* filteredG array elements are changing with various route selection during route filtering.
    const filteredG = Object.values(geofences)
      .filter((geofence) => !filterGeofences.length || filterGeofences.includes(geofence.id));

    //* geofence filter in device
    setFilteredGeofences(filteredG);
  }, [geofences, stoppages, filterGeofences]);

  return (
    <div className={classes.root}>
      <MapView>
        <MapOverlay />
        <MapGeofence routes={stoppageSearch ? (selectedItem ? stoppageGeofences : Object.values(geofences)) : filteredGeofences} />
        {!hideDevices && filteredDevices.map((device) => <MapAccuracy key={device.id} deviceId={device.id} />)}
        {!hideStoppages && <MapMarkers onClick={onClick} markers={stoppageSearch ? (selectedItem ? filteredStoppages : Object.values(stoppages)) : filteredStoppages} />}
        {selectedItem ? (
          <MapCameraMain key={selectedItem.id} longitude={selectedItem.longitude} latitude={selectedItem.latitude} />
        ) : (
          null // TODO set mapCamera to initial position
        )}
        {mapLiveRoutes && <MapLiveRoutes />}
        {!hideDevices && <MapPositions positions={filteredPositions} onClick={onClick} showStatus entries={tripEntries} />}
        {selectedPosition && selectedPosition.course && (
          <MapDirection position={selectedPosition} />
        )}
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      {!features.disableEvents && <MapNotification enabled={eventsAvailable} onClick={eventHandler} />}
      {desktop && <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />}
      <Button
        variant="contained"
        color={phone ? 'secondary' : 'primary'}
        classes={{ containedPrimary: classes.sidebarToggleBg }}
        className={classes.sidebarToggle}
        onClick={handleClose}
        disableElevation
      >
        <ListIcon />
        <div className={classes.sidebarToggleText}>{t('deviceTitle')}</div>
      </Button>
      <Paper square elevation={3} className={`${classes.sidebar} ${!devicesOpen && classes.sidebarCollapsed}`}>
        <Paper square elevation={3} className={classes.toolbarContainer}>
          <Toolbar className={classes.toolbar} disableGutters>
            {!desktop && (
              <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <OutlinedInput
              ref={filterRef}
              placeholder={stoppageSearch ? t('searchStoppage') : t('searchVehicle')}
              value={stoppageSearch ? (selectedItem ? selectedItem.name : filterKeywordStoppage) : filterKeyword}
              //* onchange value alphabetic sort dismiss
              onChange={(e) => (stoppageSearch ? (selectedItem ? setSelectedItem(false) : (setFilterKeywordStoppage(e.target.value))) : setFilterKeyword(e.target.value))}
              startAdornment={(
                <InputAdornment position="start">
                  <DirectionsBusIcon fontSize="small" sx={{ marginInlineEnd: 0.3 }} color={DirectionsBusColor} />
                  <LocationOnIcon fontSize="small" sx={{ marginInlineEnd: 0.3 }} color={LocationOnIconColor} />
                </InputAdornment>
              )}
              endAdornment={(
                <InputAdornment position="end">
                  {stoppageSearch ? (
                    selectedItem ? (
                      <IconButton onClick={handleClear}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    ) : null
                  ) : (
                    <Tooltip title="Open filter box">
                      <IconButton size="small" onClick={() => setFilterAnchorEl(filterRef.current)}>
                        <Badge color="primary" variant="dot" invisible={!hideStoppages && !hideDevices && !allDevices}>
                          <SortIcon fontSize="small" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={stoppageSearch ? 'Search vehicles' : 'Search stoppages'}>
                    <SearchSwitch onChange={searcher} disabled={searchDisablity} sx={{ marginInlineEnd: 0.6 }} />
                  </Tooltip>
                </InputAdornment>
              )}
              sx={{
                '& fieldset': { border: 'none' },
                boxShadow: 1,
                height: 47,
              }}
              size="small"
              fullWidth
            />
            <Popover
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              onClose={() => setFilterAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {/* mute switch  */}
              <div className={classes.switch} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Tooltip title={hideDevices ? 'Show devices on map' : 'Hide devices on map'}>
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement="start"
                      sx={{ marginBottom: 0.2 }}
                      label={hideDevices ? <GpsNotFixedIcon fontSize="small" style={{ color: 'gray' }} /> : <GpsFixedIcon fontSize="small" style={{ color: 'gray' }} />}
                      control={!Object.values(devices).length ? (<Switch disabled size="small" />) : (<Switch checked={hideDevices} onChange={deviceHider} size="small" />)}
                    />
                  </FormGroup>
                </Tooltip>
                <Tooltip title={hideStoppages ? 'Show stoppages on map' : 'Hide stoppages on map'}>
                  <FormGroup>
                    <FormControlLabel
                      labelPlacement="start"
                      sx={{ marginBottom: 0.2 }}
                      label={hideStoppages ? <LocationOffIcon fontSize="small" style={{ color: 'gray' }} /> : <LocationOnIcon fontSize="small" style={{ color: 'gray' }} />}
                      control={<Switch checked={hideStoppages} onChange={stoppageHider} size="small" />}
                    />
                  </FormGroup>
                </Tooltip>
                {(!admin && filteredDevices.length === 1 && filteredDevices.map((device) => device.speciality).pop()) || (
                  <Tooltip title={allDevices ? 'Show active vehicles' : 'Show all vehicles'}>
                    <FormGroup>
                      <FormControlLabel
                        labelPlacement="start"
                        label={allDevices ? <DirectionsBusIcon fontSize="small" style={{ color: 'gray' }} /> : <DepartureBoardIcon fontSize="small" style={{ color: 'gray' }} />}
                        control={<Switch checked={allDevices} onChange={showAllDevices} size="small" />}
                      />
                    </FormGroup>
                  </Tooltip>
                )}
              </div>
              <div className={classes.filterPanel}>
                <FormControl>
                  <InputLabel>{t('deviceStatus')}</InputLabel>
                  <Select
                    label={t('deviceStatus')}
                    value={filterStatuses}
                    onChange={(e) => { setFilterStatuses(e.target.value === 'clear' ? '' : e.target.value); setFilterMap(e.target.value); }}
                  >
                    <MenuItem key="clear" value="clear"><Typography color="red"><b>Discard</b></Typography></MenuItem>
                    <MenuItem value="online">{t('deviceStatusOnline')}</MenuItem>
                    <MenuItem value="offline">{t('deviceStatusOffline')}</MenuItem>
                    <MenuItem value="unknown">{t('deviceStatusUnknown')}</MenuItem>
                  </Select>
                </FormControl>
                {admin && (
                  <FormControl>
                    <InputLabel>{t('filterByUserType')}</InputLabel>
                    <Select
                      label={t('filterByUserType')}
                      value={filterUser}
                      disabled={Boolean(filterGeofences.length)}
                      onChange={(e) => { setFilterUser(e.target.value === 'clear' ? '' : e.target.value); setFilterMap(e.target.value); }}
                    >
                      <MenuItem key="clear" value="clear"><Typography color="red"><b>Discard</b></Typography></MenuItem>
                      {Object.values(users).filter((user) => (user.administrator === false)).map((user) => (<MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                )}
                <FormControl>
                  <InputLabel>{t('sharedRoutes')}</InputLabel>
                  <Select
                  //* reading value from 'resources/l10n/en.json'
                    label={t('sharedRoutes')}
                    value={filterGeofences}
                    onChange={(e) => { setFilterGeofences(e.target.value); setGeofenceStoppaes(e.target.value); setFilterMap(e.target.value); }}
                    multiple
                  >
                    {/* menuItems color is being set in style property */}
                    {Object.values(filterUser ? userGeofences : geofences).map((geofence) => (<MenuItem key={geofence.id} value={geofence.id} style={{ color: geofence.attributes.color }}><Typography display="inline" color={geofence.attributes.color}>{geofence.name}</Typography></MenuItem>))}
                  </Select>
                </FormControl>
              </div>
            </Popover>
            <Tooltip title="Add new vehicle">
              <IconButton onClick={() => navigate('/settings/device')} disabled={deviceReadonly}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            {desktop && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Toolbar>
        </Paper>
        <table>
          <tbody>
            <tr>
              <td className={classes.filterBox}>
                <Badge sx={{ marginInlineEnd: '1rem' }}>
                  <FilterAltIcon fontSize="inherit" />
                  <Typography color="ButtonText" variant="caption">{t('sharedFilters')}</Typography>
                </Badge>
                <Badge color="info" variant="dot" invisible={!filterKeyword.length} sx={{ marginInlineEnd: '1rem' }}>
                  <Typography color="#9e9e9e" variant="caption">{t('filterByKeyword')}</Typography>
                </Badge>
                <Badge color={filterStatuses === 'online' ? 'success' : 'error'} variant="dot" invisible={!filterStatuses.length} sx={{ marginInlineEnd: '1rem' }}>
                  <Typography color="#9e9e9e" variant="caption">{t('filterByStatus')}</Typography>
                </Badge>
                {admin && (
                  <Badge color="info" variant="dot" invisible={!filterUser} sx={{ marginInlineEnd: '1rem' }}>
                    <Typography color="#9e9e9e" variant="caption">{t('filterByUserType')}</Typography>
                  </Badge>
                )}
                <Badge color="info" variant="dot" invisible={!filterGeofences.length} sx={{ marginInlineEnd: '1rem' }}>
                  <Typography color="#9e9e9e" variant="caption">{t('filterByRoutes')}</Typography>
                </Badge>
                <Badge color="info" variant="dot" invisible={!selectedItem} sx={{ marginInlineEnd: '1rem' }}>
                  <Typography color="#9e9e9e" variant="caption">{t('filterByStoppage')}</Typography>
                </Badge>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={classes.deviceList} style={{ maxHeight: '100%', overflow: 'auto' }}>
          {stoppageSearch ? (
            filterKeywordStoppage && !selectedItem && !hideStoppages ? (
              searchedS.sort((a, b) => a.name.localeCompare(b.name)).map((stoppage) => (
                <div key={stoppage.id} style={style}>
                  <ListItemButton
                    className={classes.listItem}
                    onClick={() => setSelectedItem(stoppage)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <img className={classes.icon} src={mapIcons[mapIconKey('default')]} alt="" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={stoppage.name}
                      primaryTypographyProps={{ noWrap: true }}
                      secondary={<Item item={stoppage} fontSize="0.8rem" fontStyle="italic" />}
                      secondaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItemButton>
                </div>
              ))
            ) : (
              <DevicesList devices={filteredDevices} tripEntries={tripEntries} />
            )) : (
              <DevicesList devices={filteredDevices} tripEntries={tripEntries} />
          )}
          {/* <DevicesList devices={specialVehicles} /> */}
        </div>
      </Paper>
      {desktop && (
        <div className={classes.bottomMenu}>
          <BottomMenu />
        </div>
      )}
      {!features.disableEvents && <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />}
      {selectedDeviceId && (
        <div className={classes.statusCard}>
          <StatusCard
            deviceId={selectedDeviceId}
            onClose={() => dispatch(devicesActions.select(null))}
          />
        </div>
      )}
    </div>
  );
};

export default MainPage;
