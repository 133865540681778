import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import {
  Table, TableBody, TableCell, TableHead, TableRow, Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddEntry from './components/AddEntry';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import { useEffectAsync } from '../reactHelper';
import TableShimmer from '../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));
const EntryAssign = () => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/users?all=true');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);
  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedMaintenance']}>
      <Container maxWidth="xs">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Add Entry For:</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.filter((user) => user.administrator === false).map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell className={classes.columnAction} padding="none">
                  <AddEntry itemId={item.id} editPath="/settings/scheduleEntry" endpoint="entries" setTimestamp={setTimestamp} />
                </TableCell>
              </TableRow>
            )) : (<TableShimmer columns={2} endAction />)}
          </TableBody>
        </Table>
      </Container>
    </PageLayout>
  );
};

export default EntryAssign;
