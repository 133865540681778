import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActionsFuel from './components/CollectionActionsFuel';
import TableShimmer from '../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const FuelsPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/fuels');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'settingsFuels']}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('FuelAmmount')}</TableCell>
            <TableCell>{t('FuelPrice')}</TableCell>
            <TableCell>{t('FuelDate')}</TableCell>
            <TableCell>{t('FuelMemo')}</TableCell>
            <TableCell>{t('FuelVehicle')}</TableCell>
            <TableCell>{t('FuelAdmin')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.ammount}</TableCell>
              <TableCell>{item.price}</TableCell>
              <TableCell>{item.date}</TableCell>
              <TableCell>{item.memo}</TableCell>
              <TableCell>{item.vehicle}</TableCell>
              <TableCell>{item.admin}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActionsFuel itemId={item.id} editPath="/reports/fuel" endpoint="fuels" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={7} endAction />)}
        </TableBody>
      </Table>
      <CollectionFab editPath="/reports/fuel" />
    </PageLayout>
  );
};

export default FuelsPage;
