import React, { useState, useEffect } from 'react';
import {
  Table, TableRow, TableCell, TableHead, Grid, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionSchedules from './components/CollectionSchedules';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import CollectionEntryAssign from './components/CollectionEntryAssign';
import CollectionEntries from './components/CollectionEntries';
import TableShimmer from '../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const Entries = () => {
  const classes = useStyles();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState('');
  const routeApiUrl = '/api/geofences';

  useEffect(() => {
    fetch(routeApiUrl)
      .then((response) => response.json())
      .then((data) => setRoutes(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/entries');
      if (response.ok) {
        setEntries(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);
  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <div style={{ padding: '10px 10px 10px 10px' }}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Table>
              <TableHead>
                <TableRow>Schedule Entries</TableRow>
                <TableRow>
                  <TableCell>Entry Date</TableCell>
                  <TableCell>Route</TableCell>
                  <TableCell>Shift & Trip</TableCell>
                  <TableCell>Departure time</TableCell>
                  <TableCell>Arrival time</TableCell>
                  <TableCell className={classes.columnAction} />
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? entries.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.entry_date}</TableCell>
                    <TableCell>
                      {
                        Array.from(routes).filter((route) => (route.id === item.routeid)).map((r) => (
                          r.name
                        ))
                      }
                    </TableCell>
                    <TableCell>
                      {item.trip_name}
                      {' - '}
                      {item.shift}
                    </TableCell>
                    <TableCell>{item.departure_time}</TableCell>
                    <TableCell>{item.arrival_time}</TableCell>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActions itemId={item.id} editPath="/settings/scheduleEntry" endpoint="entries" setTimestamp={setTimestamp} />
                    </TableCell>
                  </TableRow>
                )) : (<TableShimmer columns={7} endAction />)}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
      <CollectionFab editPath="/settings/schedule" />
      <CollectionEntryAssign editPath="/settings/EntryAssign" />
      <CollectionSchedules editPath="/settings/scheduleMaintenance" />
      <CollectionEntries editPath="/settings/Entries" />
    </PageLayout>
  );
};

export default Entries;
