import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableRow, TableCell, IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from '../common/components/LocalizationProvider';
import theme from '../common/theme';

const SchedulesPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  function tConv24(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }
  const [schedules, setSchedules] = useState([]);
  const scheduleApiUrl = '/api/schedules?all=true';
  useEffect(() => {
    fetch(scheduleApiUrl)
      .then((response) => response.json())
      .then((data) => setSchedules(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const activeid = parseInt((Object.values(schedules).filter((schedule) => (schedule.active_status === 'True')).map((sc) => sc.id)), 10);
  const act = `/api/entries?scheduleId=${activeid}`;
  const [entry, setEntry] = useState([]);
  useEffect(() => {
    fetch(act)
      .then((response) => response.json())
      .then((data) => setEntry(data))
      .catch((error) => {
        throw error;
      });
  }, [!activeid]);
  let t1 = false;
  let t2 = false;
  let t3 = false;
  let t4 = false;
  let t5 = false;
  let t6 = false;
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  const currentDate = `${yyyy}-${mm}-${dd}`;
  for (let i = 0; i < entry.length; i += 1) {
    if (entry[i].trip_name === 'First Trip') {
      t1 = true;
    } else if (entry[i].trip_name === 'Second Trip') {
      t2 = true;
    } else if (entry[i].trip_name === 'Third Trip') {
      t3 = true;
    } else if (entry[i].trip_name === 'Fourth Trip') {
      t4 = true;
    } else if (entry[i].trip_name === 'Fifth Trip') {
      t5 = true;
    } else if (entry[i].entry_date === currentDate) {
      t6 = true;
    }
  }
  const [routes, setRoutes] = useState('');
  const routeApiUrl = '/api/geofences?all=true';

  useEffect(() => {
    fetch(routeApiUrl)
      .then((response) => response.json())
      .then((data) => setRoutes(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [helpers, setHelpers] = useState('');
  const helperApiUrl = '/api/helpers?all=true';

  useEffect(() => {
    fetch(helperApiUrl)
      .then((response) => response.json())
      .then((data) => setHelpers(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [drivers, setDrivers] = useState('');
  const driverApiUrl = '/api/drivers?all=true';
  useEffect(() => {
    fetch(driverApiUrl)
      .then((response) => response.json())
      .then((data) => setDrivers(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [devices, setDevices] = useState('');
  const deviceApiUrl = '/api/devices?all=true';

  useEffect(() => {
    fetch(deviceApiUrl)
      .then((response) => response.json())
      .then((data) => setDevices(data))
      .catch((error) => {
        throw error;
      });
  }, []);

  return (
    <div>
      <div style={{ top: 0, right: 0, paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1) }}>
        <IconButton>
          <ArrowBackIcon onClick={() => navigate(-1)} />
        </IconButton>
      </div>
      <div style={{ textAlignVertical: 'center', textAlign: 'center' }}>
        <br />
        Khulna University, Khulna
        <br />
        Paribahan Pool
        <p style={{ marginTop: '20px', marginBottom: '25px', fontStyle: 'italic' }}>
          {
            Array.from(schedules).filter((schedule) => (schedule.active_status === 'True')).map((r) => (
              r.notice
            ))
          }
        </p>
        {t6 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>Special Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>Trip</b></TableCell>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name !== 'First Trip' && filter.trip_name !== 'Second Trip' && filter.trip_name !== 'Third Trip' && filter.trip_name !== 'Fourth Trip' && filter.trip_name !== 'Fifth Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">{re.trip_name}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
        {t1 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>First Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name === 'First Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
        {t2 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>Second Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name === 'Second Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
        {t3 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>Third Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name === 'Third Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
        {t4 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>Fourth Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name === 'Fourth Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
        {t5 ? (
          <Table style={{ marginTop: '10px' }}>
            <TableRow>
              <TableCell align="center" colSpan={7}><h3>Fifth Trip</h3></TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center"><b>{t('sharedRoute')}</b></TableCell>
              <TableCell align="center"><b>Departure Time</b></TableCell>
              <TableCell align="center"><b>Return Time</b></TableCell>
              <TableCell align="center"><b>Arrival Time</b></TableCell>
              <TableCell align="center"><b>Vehicle No</b></TableCell>
              <TableCell align="center"><b>Driver & Helper Name</b></TableCell>
            </TableRow>
            {
            entry.filter((filter) => filter.trip_name === 'Fifth Trip').map((re) => (
              <TableRow key={re.id}>
                <TableCell align="center">
                  {
                    Array.from(routes).filter((route) => (route.id === re.routeid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">{tConv24(re.departure_time)}</TableCell>
                <TableCell align="center">{tConv24(re.return_time)}</TableCell>
                <TableCell align="center">{tConv24(re.arrival_time)}</TableCell>
                <TableCell align="center">
                  {
                    Array.from(devices).filter((vehicle) => (vehicle.id === re.vehicleid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    Array.from(drivers).filter((driver) => (driver.id === re.driverid)).map((r) => (
                      r.name
                    ))
                  }
                  {'  '}
                  {
                    Array.from(helpers).filter((helper) => (helper.id === re.helperid)).map((r) => (
                      r.name
                    ))
                  }
                </TableCell>
              </TableRow>
            ))
          }
          </Table>
        ) : (null)}
      </div>
    </div>
  );
};

export default SchedulesPage;
