import React, { useEffect, useState } from 'react';
import {
  Accordion, AccordionDetails, Table, TableRow, TableCell, TableHead, Select, MenuItem, TextField,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { useSelector } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import makeStyles from '@mui/styles/makeStyles';
import EditItemViewEntry from './components/EditItemViewEntry';
import SelectFieldRoute from '../common/components/SelectFieldRoute';
import SelectField from '../common/components/SelectField';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const ScheduleEntryPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const val = new URLSearchParams(document.location.search);
  const baseId = parseInt(val.get('id'), 10);
  const [item, setItem] = useState();
  const [device, setDevice] = useState('');
  const [vehicle, setVehicle] = useState(false);
  const [option, setOption] = useState('');
  useEffect(() => {
    fetch(`/api/devices/${device}`)
      .then((Response) => Response.json())
      .then((data) => setVehicle(data));
  }, [device]);
  // console.log(baseId);
  const handleGeofence = (ev) => {
    const val = ev.target.value;
    setItem({ ...item, routeid: val });
  };
  const handleTrip = (e) => {
    const val = e.target.value;
    setOption(val);
    setItem({ ...item, trip_name: val });
  };
  const handleDriver = (ev) => {
    const val = ev.target.value;
    setItem({ ...item, driverid: val });
    const body = {};
    const userId = 'userId';
    const driverId = 'driverId';
    body[userId] = baseId;
    body[driverId] = val;
    const results = [];
    results.push(fetch('/api/permissions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }));
    // Promise.all(results);
  };
  const handleVehicle = (ev) => {
    const val = ev.target.value;
    setDevice(val);
    setItem({ ...item, vehicleid: val, driverid: vehicle.driverId, routeid: vehicle.geofenceId });
  };
  const handleHelper = (ev) => {
    const val = ev.target.value;
    setItem({ ...item, helperid: val });
    const body = {};
    const userId = 'userId';
    const helperId = 'helperId';
    body[userId] = baseId;
    body[helperId] = val;
    const results = [];
    results.push(fetch('/api/permissions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }));
    // Promise.all(results);
  };

  const users = useSelector((state) => state.users.items);
  const validate = () => item && item.departure_time && item.return_time;
  return (
    <EditItemViewEntry
      endpoint="entries"
      item={item}
      setItem={setItem}
      user={baseId}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'scheduleDialog']}
    >
      {item && (
        <Accordion defaultExpanded>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {`Schedule Entry Form : ${baseId ? users[baseId].name : users[item.user_type].name}`}
                </TableCell>
                <TableCell className={classes.columnAction} />
              </TableRow>
            </TableHead>
          </Table>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.entry_date || ''}
              onChange={(event) => setItem({ ...item, entry_date: event.target.value })}
              label="Entry Date"
              type="date"
              focused
            />
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Trip Name</FormLabel>
              <Select value={option || item.trip_name} onChange={handleTrip}>
                <MenuItem value="First Trip">First Trip</MenuItem>
                <MenuItem value="Second Trip">Second Trip</MenuItem>
                <MenuItem value="Third Trip">Third Trip</MenuItem>
                <MenuItem value="Fourth Trip">Fourth Trip</MenuItem>
                <MenuItem value="Fifth Trip">Fifth Trip</MenuItem>
                <MenuItem value="Custom">Custom</MenuItem>
              </Select>
            </FormControl>
            {option === 'Custom' && (
              <TextField
                style={{ paddingRight: '5px' }}
                type="text"
                label="Custom Trip"
                focused
                onChange={(e) => setItem({ ...item, trip_name: e.target.value })}
              />
            )}
            {baseId ? (
              <SelectField
                value={item.vehicleid}
                emptyValue={null}
                onChange={handleVehicle}
                endpoint={`/api/devices?userId=${baseId}`}
                label="Vehicle Name"
              />
            ) : (
              <SelectField
                value={item.vehicleid}
                emptyValue={null}
                onChange={(event) => setItem({ ...item, vehicleid: event.target.value })}
                endpoint={`/api/devices?userId=${item.user_type}`}
                label="Vehicle Name"
              />
            )}
            {baseId ? (
              <SelectField
                value={item.driverid || vehicle.driverId}
                emptyValue={null}
                onChange={handleDriver}
                endpoint="/api/drivers"
                label={t('sharedDriverName')}
              />
            ) : (
              <SelectField
                value={item.driverid}
                emptyValue={null}
                onChange={(event) => setItem({ ...item, driverid: event.target.value })}
                endpoint="/api/drivers"
                label={t('sharedDriverName')}
              />
            )}
            {baseId ? (
              <SelectField
                value={item.helperid}
                emptyValue={null}
                onChange={handleHelper}
                endpoint="/api/helpers"
                label={t('sharedHelperName')}
              />
            ) : (
              <SelectField
                value={item.helperid}
                emptyValue={null}
                onChange={(event) => setItem({ ...item, helperid: event.target.value })}
                endpoint="/api/helpers"
                label={t('sharedHelperName')}
              />
            )}
            {baseId ? (
              <TextField
                value={item.departure_time || ''}
                onChange={((event) => (setItem({ ...item, departure_time: event.target.value, user_type: baseId })))}
                label={t('departureCampus')}
                type="time"
                focused
              />
            ) : (
              <TextField
                value={item.departure_time || ''}
                onChange={(event) => (setItem({ ...item, departure_time: event.target.value }))}
                label={t('departureCampus')}
                type="time"
                focused
              />
            )}
            <TextField
              value={item.return_time || ''}
              onChange={(event) => setItem({ ...item, return_time: event.target.value })}
              label={t('departureDestination')}
              type="time"
              focused
            />
            <TextField
              value={item.arrival_time || ''}
              onChange={(event) => setItem({ ...item, arrival_time: event.target.value })}
              label={t('arrivalCampus')}
              type="time"
              focused
            />
            {baseId ? (
              <SelectFieldRoute
                value={item.routeid || vehicle.geofenceId}
                emptyValue={null}
                onChange={handleGeofence}
                endpoint={`/api/geofences?userId=${baseId}`}
                label={t('sharedRoute')}
              />
            ) : (
              <SelectFieldRoute
                value={item.routeid}
                emptyValue={null}
                onChange={(event) => setItem({ ...item, routeid: event.target.value })}
                endpoint={`/api/geofences?userId=${item.user_type}`}
                label={t('sharedRoute')}
              />
            )}
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Shift</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={item.shift || ''}
                onChange={(event) => setItem({ ...item, shift: event.target.value })}
              >
                <FormControlLabel value="Morning" control={<Radio />} label="Morning" />
                <FormControlLabel value="Noon" control={<Radio />} label="Noon" />
                <FormControlLabel value="Afternoon" control={<Radio />} label="Afternoon" />
                <FormControlLabel value="Others" control={<Radio />} label="Others" />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemViewEntry>
  );
};

export default ScheduleEntryPage;
