import React, { useId, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { IconButton } from '@mui/material';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import ReactDOM from 'react-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { map } from '../core/MapView';
import { findFonts, geofenceToFeature } from '../core/mapUtil';

//* passing 'routes' as an argument, which is used by '<MapGeofence />' in MainPage.js
const MapGeofence = ({ onClick, routes }) => {
  const id = useId();

  const theme = useTheme();

  const geofences = useSelector((state) => state.geofences.items);

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMapClick = useCallback((event) => {
    if (!event.preventDefault) {
      onClick();
    }
  }, [onClick]);

  const onGeofenceClick = useCallback((event) => {
    event.preventDefault();
    //* feature has the first element of features array
    // const feature = event.features[0];
    /** this 'if' statement takes onClick function as an argument.
    proceed inside while if statemet is true. */
    if (onClick) {
      const popup = new AnimatedPopup({
        closeButton: false,
        maxWidth: 'auto',
        openingAnimation: {
          duration: 400,
          easing: 'easeInOutQuint',
          transform: 'scale',
        },
        closingAnimation: {
          duration: 15,
          easing: 'easeInBack',
          transform: 'scale',
        },
      });

      //* copyButton inside lngLat popUp
      const handleCopy = () => {
        const popupContent = document.querySelector('.mapboxgl-popup-content');
        const latLngText = popupContent.querySelector('Typography').textContent;
        const [lat, lng] = latLngText.match(/\d+\.\d+/g);
        navigator.clipboard.writeText(`${lat} ${lng}`);

        //* Replace the copy IconButton with a new IconButton
        const copyButton = popupContent.querySelector('.copy-button');
        const iconButton = (
          <IconButton disabled>
            <LibraryAddCheckIcon fontSize="small" style={{ color: 'green' }} />
          </IconButton>
        );
        ReactDOM.render(iconButton, copyButton);
      };

      const popupContent = document.createElement('div');

      const textContainer = document.createElement('div');
      textContainer.style.display = 'flex';
      textContainer.style.alignItems = 'center';

      const typography = document.createElement('Typography');
      typography.style.display = 'inline';
      typography.textContent = `Latitude: ${event.lngLat.lat} Longitude: ${event.lngLat.lng}`;
      textContainer.appendChild(typography);

      const copyButton = document.createElement('div');
      copyButton.className = 'copy-button';
      const iconButton = (
        <IconButton onClick={handleCopy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      );
      ReactDOM.render(iconButton, copyButton);
      textContainer.appendChild(copyButton);

      popupContent.appendChild(textContainer);

      popup.setLngLat(event.lngLat)
        .setDOMContent(popupContent)
        .addTo(map.on('move', () => { popup.remove(); }));
    }
  }, [onClick]);

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
    map.addLayer({
      source: id,
      id: 'geofences-fill',
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
      ],
      paint: {
        'fill-color': ['get', 'color'],
        'fill-outline-color': ['get', 'color'],
        'fill-opacity': 0.1,
      },
    });
    map.addLayer({
      source: id,
      id: 'geofences-line',
      type: 'line',
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 7,
        'line-opacity': 0.7,
      },
    });
    map.addLayer({
      source: id,
      id: 'geofences-title',
      type: 'symbol',
      layout: {
        'text-field': '{name}',
        'text-font': findFonts(map),
        'text-size': 20,
      },
      paint: {
        'text-halo-color': 'white',
        'text-halo-width': 2,
      },
    });

    map.on('mouseenter', 'geofences-line', onMouseEnter);
    map.on('mouseleave', 'geofences-line', onMouseLeave);
    map.on('click', 'geofences-line', onGeofenceClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', 'geofences-line', onMouseEnter);
      map.off('mouseleave', 'geofences-line', onMouseLeave);
      map.off('click', 'geofences-line', onGeofenceClick);
      map.off('click', onMapClick);

      if (map.getLayer('geofences-fill')) {
        map.removeLayer('geofences-fill');
      }
      if (map.getLayer('geofences-line')) {
        map.removeLayer('geofences-line');
      }
      if (map.getLayer('geofences-title')) {
        map.removeLayer('geofences-title');
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: routes.map((geofence) => geofenceToFeature(theme, geofence)),
    });
  }, [geofences, routes]);

  return null;
};

export default MapGeofence;
