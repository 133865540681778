import React, { useState, useEffect } from 'react';
import {
  TableCell, TableRow,
} from '@mui/material';
import CollectionActions from './CollectionActions';
import useReportStyles from '../common/useReportStyles';

const BookSummary = ({ device, start, end }) => {
  const classes = useReportStyles();
  const [items, setItems] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  function LastDate(date, month, year) {
    let newDate = date - 6;
    let newMonth = month;
    let newYear = year;
    if (newDate < 1) {
      newDate += 30;
      newMonth = month - 1;
      if (newMonth === 0) {
        newMonth = 12;
        newYear = year - 1;
      }
    }
    return `${newYear}${newMonth}${newDate}`;
  }
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const Today = `${year}${month}${date}`;
  const Last = LastDate(date, month, year);

  useEffect(() => {
    fetch('/api/logbook/')
      .then((res) => res.json())
      .then((data) => setItems(data))
      .catch((error) => {
        throw error;
      });
  }, [device]);

  useEffect(() => {
    fetch(`/api/devices/${device.id}`)
      .then((res) => res.json())
      .then((data) => setVehicle(data))
      .catch((error) => {
        throw error;
      });
  }, [device]);

  const deviceLogs = items.filter((fi) => (fi.deviceId === device.id));
  const filteredLogs = start
    ? end
      ? deviceLogs
        .filter((x) => x.date.split('-').join('') >= start && x.date.split('-').join('') <= end)
      : deviceLogs.filter((fi) => (fi.date.split('-').join('')) === start)
    : deviceLogs.filter((x) => x.date.split('-').join('') >= Last && x.date.split('-').join('') <= Today);
  let filteredDistance = 0;
  filteredLogs.forEach((x) => (filteredDistance += x.distance));

  let filteredFuel = 0;
  filteredLogs.forEach((x) => (filteredFuel += x.fuelUse));
  let milege = 0;
  milege = (filteredDistance / filteredFuel);
  // console.log(vehicle);
  return (
    <TableRow key={device.id}>
      <TableCell>{vehicle.name}</TableCell>
      <TableCell>
        {filteredDistance}
        km
      </TableCell>
      <TableCell>
        {filteredFuel}
        L
      </TableCell>
      <TableCell>
        {!milege ? 0 : milege.toFixed(3) }
        km/L
      </TableCell>
      <TableCell className={classes.columnAction} padding="none">
        <CollectionActions itemId={device.id} editPath="/reports/LogEntry" endpoint="reports" />
      </TableCell>
    </TableRow>
  );
};

export default BookSummary;
