import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from '../components/EditItemView';
import LinkFieldRoute from '../../common/components/LinkFieldRoute';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SettingsMenu from '../components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const StoppagePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item && item.name && item.latitude && item.longitude;

  return (
    <EditItemView
      endpoint="stoppages"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsStoppage']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <Typography style={{ fontSize: 12 }}>{t('warnMessageStoppage')}</Typography>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.latitude || ''}
                onChange={(event) => setItem({ ...item, latitude: event.target.value })}
                label={t('settingsLatitude')}
              />
              <TextField
                value={item.longitude || ''}
                onChange={(event) => setItem({ ...item, longitude: event.target.value })}
                label={t('settingsLongitude')}
              />
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkFieldRoute
                  endpointAll="/api/geofences"
                  endpointLinked={`/api/geofences?stoppageId=${item.id}`}
                  baseId={item.id}
                  keyBase="stoppageId"
                  keyLink="geofenceId"
                  label={t('sharedRoutes')}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default StoppagePage;
