import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableRow, TextField, MenuItem, Select, FormControl, InputLabel,
} from '@mui/material';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import { useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';
import BookSummary from './components/BookSummary';

const LogBookPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  let start = startDate.split('-').join('');
  let end = endDate.split('-').join('');
  // const [from, setFrom] = useState(moment().subtract(1, 'hour'));
  // const [to, setTo] = useState(moment());

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, []);
  const [option, setOption] = useState('today');
  const today = new Date();
  const date = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  let Yesterday;
  const Today = `${year}${month.toString().padStart(2, '0')}${date.toString().padStart(2, '0')}`;
  if (date === 1) {
    let Date = 30;
    let Month = month - 1;
    if (Month === 1 || Month === 3 || Month === 5 || Month === 7 || Month === 8 || Month === 10 || Month === 12) {
      Date = 31;
    }
    if (Month === 2) {
      if (year % 400 === 0 || year % 4 === 0) {
        Date = 29;
      } else {
        Date = 28;
      }
    }
    let Year = year;
    if (Month === 0) {
      Month = 12;
      Year = year - 1;
    }
    Yesterday = `${Year}${Month.toString().padStart(2, '0')}${Date.toString().padStart(2, '0')}`;
  } else {
    Yesterday = `${year}${month.toString().padStart(2, '0')}${date.toString().padStart(2, '0') - 1}`;
  }
  function LastDate(date, month, year) {
    let newDate = date - 6;
    let newMonth = month;
    let newYear = year;
    if (newDate < 1) {
      newDate += 30;
      newMonth = month - 1;
      if (newMonth === 0) {
        newMonth = 12;
        newYear = year - 1;
      }
    }
    return `${newYear}${newMonth.toString().padStart(2, '0')}${newDate.toString().padStart(2, '0')}`;
  }
  const Last = LastDate(date, month, year);
  switch (option) {
    case 'today':
      start = Today;
      end = null;
      break;
    case 'yesterday':
      start = Yesterday;
      end = null;
      break;
    case 'week':
      start = Last;
      end = Today;
      break;
    case 'month':
      start = `${year}${month.toString().padStart(2, '0')}${'01'}`;
      end = `${year}${month.toString().padStart(2, '0')}${31}`;
      break;
    default:
      break;
  }

  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <div style={{ textAlign: 'center', margin: '15px' }}>
        <b>{t('LogbookReport')}</b>
      </div>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <InputLabel id="demo-controlled-open-select-label">Period</InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Date Filter"
                  defaultValue="today"
                  onChange={(e) => setOption(e.target.value)}
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>
                  <MenuItem value="week">This Week</MenuItem>
                  <MenuItem value="month">This Month</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            {option === 'custom' && (
              <TableCell>
                <TextField
                  style={{ paddingRight: '5px' }}
                  type="date"
                  label="Start Date"
                  focused
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </TableCell>
            )}
            {option === 'custom' && (
              <TableCell>
                <TextField
                  style={{ paddingRight: '5px' }}
                  type="date"
                  label="End Date"
                  focused
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </TableCell>
            )}
            {option !== 'custom' && (
              <TableCell />
            )}
            {option !== 'custom' && (
              <TableCell />
            )}
            <TableCell />
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell><b>Vehicle</b></TableCell>
            <TableCell><b>Distance</b></TableCell>
            <TableCell><b>Fuel</b></TableCell>
            <TableCell><b>Mileage</b></TableCell>
            <TableCell className={classes.columnAction} padding="none" />
          </TableRow>
          {!loading ? items.map((item) => (
            <BookSummary device={item} start={start} end={end} key={item.id} />
          )) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default LogBookPage;
