import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, Grid, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import { schedulesActions } from '../store';
import CollectionActions from './components/CollectionActions';
import CollectionSchedules from './components/CollectionSchedules';
import CollectionEntryAssign from './components/CollectionEntryAssign';
import CollectionEntries from './components/CollectionEntries';
import TableShimmer from '../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const ScheduleMaintenance = () => {
  const classes = useStyles();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const schedules = useSelector((state) => state.schedules.items);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/schedules');
      if (response.ok) {
        dispatch(schedulesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);
  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDrivers']}>
      <div style={{ padding: '10px 10px 10px 10px' }}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Table>
              <TableHead>
                <TableRow>Schedules</TableRow>
                <TableRow>
                  <TableCell>Schedule Notice</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>Active Status</TableCell>
                  <TableCell className={classes.columnAction} />
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? Object.values(schedules).map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.notice}</TableCell>
                    <TableCell>{item.start_date}</TableCell>
                    <TableCell>{item.active_status}</TableCell>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActions itemId={item.id} editPath="/settings/schedule" endpoint="schedules" setTimestamp={setTimestamp} />
                    </TableCell>
                  </TableRow>
                )) : (<TableShimmer columns={4} endAction />)}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </div>
      <CollectionFab editPath="/settings/schedule" />
      <CollectionEntryAssign editPath="/settings/EntryAssign" />
      <CollectionSchedules editPath="/settings/scheduleMaintenance" />
      <CollectionEntries editPath="/settings/Entries" />
    </PageLayout>
  );
};

export default ScheduleMaintenance;
