import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';
import PageLayout from '../common/components/PageLayout';
import ReportsMenu from './components/ReportsMenu';
import CollectionAction from './components/CollectionAction';
import { useEffectAsync } from '../reactHelper';
import useReportStyles from './common/useReportStyles';
import TableShimmer from '../common/components/TableShimmer';

const VehicleLogsPage = () => {
  const classes = useReportStyles();
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [timestamp, setTimestamp] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const val = new URLSearchParams(document.location.search);
  const id = parseInt(val.get('id'), 10);
  const [devices, setDevices] = useState('');

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/logbook/');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);
  const deviceApiUrl = `/api/devices/${id}`;

  useEffect(() => {
    fetch(deviceApiUrl)
      .then((response) => response.json())
      .then((data) => setDevices(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [drivers, setDrivers] = useState('');
  const driverApiUrl = '/api/drivers';

  useEffect(() => {
    fetch(driverApiUrl)
      .then((response) => response.json())
      .then((data) => setDrivers(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [helpers, setHelpers] = useState('');
  const helperApiUrl = '/api/helpers';

  useEffect(() => {
    fetch(helperApiUrl)
      .then((response) => response.json())
      .then((data) => setHelpers(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const [geofences, setGeofences] = useState('');
  const geofenceApiUrl = '/api/geofences';
  const start = startDate.split('-').join('');
  const end = endDate.split('-').join('');
  useEffect(() => {
    fetch(geofenceApiUrl)
      .then((response) => response.json())
      .then((data) => setGeofences(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const deviceLogs = items.filter((fi) => (fi.deviceId === id));
  const filteredLogs = startDate
    ? endDate
      ? deviceLogs
        .filter((x) => x.date.split('-').join('') > start && x.date.split('-').join('') < end)
      : deviceLogs.filter((fi) => (fi.date.split('-').join('')) === start)
    : deviceLogs;
  let filteredDistance = 0;
  filteredLogs.forEach((x) => (filteredDistance += x.distance));

  let filteredFuel = 0;
  filteredLogs.forEach((x) => (filteredFuel += x.fuelUse));
  return (
    <PageLayout menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportRoute']}>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        {`Logbook of - ${devices.name}`}
      </div>
      <div>
        <TextField
          style={{ paddingRight: '5px' }}
          type="date"
          label="Start Date"
          focused
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          style={{ paddingRight: '5px' }}
          type="date"
          focused
          label="End Date"
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Route</TableCell>
            <TableCell>Purpose</TableCell>
            <TableCell>{`Distance ${filteredDistance}km`}</TableCell>
            <TableCell>{`Fuel(U) ${filteredFuel}L`}</TableCell>
            <TableCell>Start(T)</TableCell>
            <TableCell>End(T)</TableCell>
            <TableCell>Driver</TableCell>
            <TableCell>Helper</TableCell>
            <TableCell>Recruiter</TableCell>
            <TableCell>Designation</TableCell>
            <TableCell>Fuel(I)</TableCell>
            <TableCell>Fuel(R)</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? filteredLogs.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.date}</TableCell>
              <TableCell>
                {
                  Array.from(geofences).filter((geofence) => (geofence.id === item.geofenceId)).map((r) => (
                    r.name
                  ))
                }
              </TableCell>
              <TableCell>{item.travelPurpose}</TableCell>
              <TableCell>{item.distance}</TableCell>
              <TableCell>{item.fuelUse}</TableCell>
              <TableCell>{item.startTime}</TableCell>
              <TableCell>{item.endTime}</TableCell>
              <TableCell>
                {
                  Array.from(drivers).filter((driver) => (driver.id === item.driverId)).map((r) => (
                    r.name
                  ))
                }
              </TableCell>
              <TableCell>
                {
                  Array.from(helpers).filter((helper) => (helper.id === item.helperId)).map((r) => (
                    r.name
                  ))
                }
              </TableCell>
              <TableCell>{item.recruiterName}</TableCell>
              <TableCell>{item.recruiterDesignation}</TableCell>
              <TableCell>{item.fuelIssue}</TableCell>
              <TableCell>{item.fuelRemaining}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionAction itemId={item.id} editPath="/reports/logentry" endpoint="logbook" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={2} endAction />)}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default VehicleLogsPage;
