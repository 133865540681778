import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';
import {
  Accordion, AccordionDetails, AccordionSummary, Typography, Table, TableRow, TableCell, TableHead,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import LinkFieldEntry from '../common/components/LinkFieldEntry';
// import LinkField from '../common/components/LinkField';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const SchedulePage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item && item.notice && item.active_status;
  // const options = [
  //   'Morning Shift', 'Noon Shift', 'Afternoon Shift', 'Special Shift', 'Evening Shift', 'Night Shift', 'National Day Shift',
  // ];
  // const defaultOption = options[0];
  return (
    <EditItemView
      endpoint="schedules"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'scheduleDialog']}
    >
      {item && (
        <>
          <Accordion defaultExpanded>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Schedule Form</TableCell>
                  <TableCell className={classes.columnAction} />
                </TableRow>
              </TableHead>
            </Table>
            <AccordionDetails className={classes.details}>
              <TextareaAutosize
                value={item.notice || ''}
                minRows={6}
                onChange={(event) => setItem({ ...item, notice: event.target.value })}
                label={t('sharedAnnouncement')}
              />
              <TextField
                value={item.start_date || ''}
                onChange={(event) => setItem({ ...item, start_date: event.target.value })}
                label={t('reportStartDate')}
                type="date"
                focused
              />
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Active Status</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="1"
                  name="radio-buttons-group"
                  value={item.active_status || true}
                  onChange={(event) => setItem({ ...item, active_status: event.target.value })}
                >
                  <FormControlLabel value="True" control={<Radio />} label="Active" />
                  <FormControlLabel value="False" control={<Radio />} label="Deactive" />
                </RadioGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          {item.id && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkFieldEntry
                  endpointAll="/api/entries?all=true"
                  endpointLinked={`/api/entries?scheduleId=${item.id}`}
                  baseId={item.id}
                  keyBase="scheduleId"
                  keyLink="entryId"
                  label="Entry"
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}
    </EditItemView>
  );
};

export default SchedulePage;
