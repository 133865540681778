import React, { useState, useEffect } from 'react';
import {
  TableCell, TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';

const FuelCalculation = ({ item }) => {
  const Device = item.deviceId;
  const pressure = item.fuelPressure;
  const [tank, setTank] = useState([]);
  const [device, setDevice] = useState([]);

  const devices = useSelector((state) => state.devices.items);

  useEffect(() => {
    fetch(`/api/devices/${Device}`)
      .then((re) => re.json())
      .then((data) => setDevice(data));
  }, [Device]);

  useEffect(() => {
    if (device.tankid !== undefined) {
      fetch(`/api/tanks/${device.tankid}`)
        .then((re) => re.json())
        .then((data) => setTank(data));
    }
  }, [device.tankid]);

  const volumeCalculator = () => {
    const dens = tank.density;
    const lHeight = pressure / (dens * 9.81);
    const len = tank.length;
    const rad = tank.radius;
    const circum = 3.1416 * (rad ** 2);
    if (tank.vertical === 'Vertical') {
      const volume = (circum * lHeight);
      return volume.toFixed(2);
    }
    const volume = ([Math.acos((rad - lHeight) / rad) * (rad ** 2) - (rad - lHeight) * Math.sqrt(2 * rad * lHeight - (lHeight ** 2))] * len);
    return volume.toFixed(2);
  };
  return (
    <TableRow key={item.id}>
      <TableCell>{`${devices[Device].name}`}</TableCell>
      <TableCell>{`${pressure} unit`}</TableCell>
      <TableCell>{`${volumeCalculator()} unit`}</TableCell>
    </TableRow>
  );
};

export default FuelCalculation;
