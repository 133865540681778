import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import ReportsMenu from './components/ReportsMenu';
import SelectField from '../common/components/SelectField';
import SelectFieldRoute from '../common/components/SelectFieldRoute';
// import SelectFieldEntry from '../common/components/SelectFieldEntry';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const Log = () => {
  const classes = useStyles();
  const val = new URLSearchParams(document.location.search);
  const id = parseInt(val.get('id'), 10);
  const t = useTranslation();
  const [item, setItem] = useState();
  const [devices, setDevices] = useState('');

  useEffect(() => {
    fetch(`/api/devices/${id}`)
      .then((response) => response.json())
      .then((data) => setDevices(data))
      .catch((error) => {
        throw error;
      });
  }, []);
  const handleEndMeter = (event) => {
    const a = event.target.value;
    const distanced = a - item.startMeter;
    setItem({ ...item, endMeter: a, distance: distanced });
  };
  const validate = () => item && item.startTime && item.endTime;

  return (
    <EditItemView
      endpoint="logbook"
      item={item}
      setItem={setItem}
      validate={validate}
      // deviceid={id}
      menu={<ReportsMenu />}
      breadcrumbs={['reportTitle', 'reportRoute']}
    >
      {item && (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">
              {`Log entry for: ${devices.name}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <TextField
              value={item.date || ''}
              onChange={(event) => setItem(
                id
                  ? { ...item, date: event.target.value, deviceId: devices.id }
                  : { ...item, date: event.target.value },
              )}
              type="date"
              label="Date"
              focused
            />
            <TextField
              value={item.startTime || ''}
              onChange={(event) => setItem(
                devices.driverId || devices.geofenceId
                  ? { ...item, startTime: event.target.value, driverId: devices.driverId, geofenceId: devices.geofenceId }
                  : { ...item, startTime: event.target.value },
              )}
              type="time"
              label="Start Time"
              focused
              className="start-time"
            />
            <TextField
              value={item.startMeter || ''}
              onChange={(event) => setItem({ ...item, startMeter: event.target.value })}
              label="Start Meter Reading"
            />
            <TextField
              value={item.travelPurpose || ''}
              onChange={(event) => setItem({ ...item, travelPurpose: event.target.value })}
              label="Travel Purpose"
            />
            <TextField
              value={item.endTime || ''}
              onChange={(event) => setItem({ ...item, endTime: event.target.value })}
              label="End Time"
              type="time"
              focused
            />
            <TextField
              value={item.endMeter || ''}
              onChange={handleEndMeter}
              label="End Meter"
            />
            <SelectField
              value={item.driverId || devices.driverId}
              emptyValue={null}
              onChange={(event) => setItem({ ...item, driverId: event.target.value })}
              endpoint="/api/drivers"
              label="Driver Name"
            />
            <SelectFieldRoute
              value={item.geofenceId || devices.geofenceId}
              emptyValue={null}
              onChange={(event) => setItem({ ...item, geofenceId: event.target.value })}
              endpoint="/api/geofences"
              label="Route "
            />
            <SelectField
              value={item.helperId}
              emptyValue={null}
              onChange={(event) => setItem({ ...item, helperId: event.target.value })}
              endpoint="/api/helpers"
              label={t('sharedHelperName')}
            />
            <TextField
              value={item.fuelIssue || ''}
              onChange={(event) => setItem({ ...item, fuelIssue: event.target.value })}
              label="Fuel Issue"
            />
            <TextField
              value={item.fuelUse || ''}
              onChange={(event) => setItem({ ...item, fuelUse: event.target.value })}
              label="Fuel Use"
            />
            <TextField
              value={item.fuelRemaining || ''}
              onChange={(event) => setItem({ ...item, fuelRemaining: event.target.value })}
              label="Fuel Remaining"
            />
            <TextField
              value={item.recruiterName || ''}
              onChange={(event) => setItem({ ...item, recruiterName: event.target.value })}
              label="Recruiter Name"
            />
            <TextField
              value={item.recruiterDesignation || ''}
              onChange={(event) => setItem({ ...item, recruiterDesignation: event.target.value })}
              label="Recruiter Designation                                                                                                        "
            />
          </AccordionDetails>
        </Accordion>
      )}
    </EditItemView>
  );
};

export default Log;
