import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Typography,
  Badge,
  Select,
  InputLabel,
  MenuItem,
  Popover,
  FormControl,
  Button,
  Tooltip,
  Box,
} from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import MapIcon from '@mui/icons-material/Map';
import SortIcon from '@mui/icons-material/Sort';
import Item from './Item';
import MapMarkers from '../../map/MapMarkers';
import MapGeofence from '../../map/main/MapGeofence';
import MapCamera from '../../map/MapCamera';
import { useEffectAsync } from '../../reactHelper';
import MapView from '../../map/core/MapView';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import { devicesActions, stoppagesActions } from '../../store';
import SettingsMenu from '../components/SettingsMenu';
import CollectionFab from '../components/CollectionFab';
import CollectionActionsStoppage from '../components/CollectionActionsStoppage';
import TableShimmer from '../../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingLeft: theme.spacing(1),
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  containerMap: {
    flexBasis: '40%',
    flexShrink: 0,
  },
  containerMain: {
    overflow: 'auto',
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: '100%', // default width
    [theme.breakpoints.up('sm')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.dimensions.drawerWidthDesktop,
    },
  },
  mapContainer: { // rename containerMap to mapContainer
    height: 0,
    overflow: 'hidden',
    transition: 'height 0.5s ease-out', // add quotes around height
  },
  show: {
    height: 400,
  },
}));

const StoppagesPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const filterRef = useRef();

  const [filterKeywordStoppage, setFilterKeywordStoppage] = useState('');

  const routes = Object.values(useSelector((state) => state.geofences.items));
  const stoppages = useSelector((state) => state.stoppages.items);
  const geofences = useSelector((state) => state.geofences.items);

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterGeofences, setFilterGeofences] = useState([]);
  const [geofenceStoppages, setGeofenceStoppaes] = useState([]);
  const [stoppageGeofences, setStoppageGeofences] = useState([]);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  //* for alphabetic sort
  const [alphabetic, setAlphabetic] = useState([]);
  const [mapVisible, setMapVisible] = useState(false);

  const mapVisibilitySetter = () => (mapVisible ? setMapVisible(false) : setMapVisible(true));

  const onClick = useCallback((_, stoppageId) => {
    dispatch(devicesActions.select(stoppageId));
  }, [dispatch]);

  const [selectedItem, setSelectedItem] = useState(false);

  const badgeInvisibilitySortIcon = !filterGeofences.length;

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/stoppages');
      if (response.ok) {
        dispatch(stoppagesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  //* filterdS is filtered Stoppage after performing search
  const filteredS = filterGeofences.length ? geofenceStoppages.length ?
    alphabetic.length
      ? alphabetic
      : Object.values(stoppages)
        .filter((stoppage) => !geofenceStoppages.length || geofenceStoppages.includes(stoppage.id))
        .filter((stoppage) => `${stoppage.name} ${stoppage.longitude} ${stoppage.latitude}`
          .toLowerCase().split(' ').join('').includes(filterKeywordStoppage.toLowerCase().split(' ').join('')))
    : []
    : alphabetic.length
      ? alphabetic
      : Object.values(stoppages)
        .filter((stoppage) => `${stoppage.name} ${stoppage.longitude} ${stoppage.latitude}`
          .toLowerCase().split(' ').join('').includes(filterKeywordStoppage.toLowerCase().split(' ').join('')));

  const stoppagesNumber = Object.values(filteredS).length;

  const arrowDownClick = () => {
    const sortedS = filteredS.sort((a, b) => a.name.localeCompare(b.name));
    setAlphabetic(sortedS);
  };

  const handleClear = () => {
    setSelectedItem(false);
    setFilterKeywordStoppage('');
  };

  const fetchGeofences = () => {
    if (selectedItem) {
      fetch(`/api/geofences?stoppageId=${selectedItem.id}`)
        .then((response) => response.json())
        .then((geofences) => setStoppageGeofences(geofences));
    }
  };

  useEffect(() => {
    Promise.all(filterGeofences.map((geofenceId) => (
      fetch(`/api/stoppages?geofenceId=${geofenceId}`)
        .then((response) => response.json()))))
      .then((stoppages) => stoppages.reduce((stoppages, item) => ([...stoppages, ...item]), []))
      .then((stoppages) => setGeofenceStoppaes(stoppages.map((stoppage) => stoppage.id)));
  }, [stoppageGeofences, filterGeofences]);

  fetchGeofences();

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'settingsStoppages']}
    >
      <div className={classes.container}>
        {/* Stoppage search filed under settings/stoppages */}
        <div className={classes.filterPanel}>
          <OutlinedInput
            disabled={Boolean(alphabetic.length)}
            ref={filterRef}
            placeholder={t('searchStoppageMap')}
            //* search and stoppage click are two different action
            value={filterKeywordStoppage ? (selectedItem ? selectedItem.name : filterKeywordStoppage) : filterKeywordStoppage}
            onChange={(e) => (selectedItem ? setSelectedItem(false) : setFilterKeywordStoppage(e.target.value))}
            startAdornment={(
              <InputAdornment position="start">
                <SearchIcon
                  fontSize="medium"
                  sx={{ marginInlineEnd: 1 }}
                  color={!filterKeywordStoppage.length ? 'disabled' : filterKeywordStoppage && filteredS.length ? 'success' : 'error'}
                />
              </InputAdornment>
            )}
            endAdornment={(
              <InputAdornment position="end">
                {selectedItem && filterKeywordStoppage ? (
                  <IconButton onClick={handleClear}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <Tooltip title="Open filter box">
                    <IconButton size="small" onClick={() => setFilterAnchorEl(filterRef.current)} disabled={Boolean(alphabetic.length)}>
                      <Badge color="primary" variant="dot" invisible={badgeInvisibilitySortIcon}>
                        <SortIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="View map">
                  <IconButton onClick={mapVisibilitySetter} disabled={Boolean(selectedItem)}>
                    {mapVisible ? <MapIcon fontSize="small" color="primary" /> : <MapIcon fontSize="small" />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )}
            sx={{
              '& fieldset': { border: 'none' },
              boxShadow: 1,
              borderRadius: 2,
              height: 47,
            }}
            size="small"
            fullWidth
          />
          <Popover
            open={!!filterAnchorEl}
            anchorEl={filterAnchorEl}
            onClose={() => setFilterAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className={classes.filterPanel}>
              {/* mute switch  */}
              <FormControl>
                <InputLabel>{t('sharedRoutes')}</InputLabel>
                <Select
                //* reading value from 'resources/l10n/en.json'
                  label={t('sharedRoutes')}
                  value={filterGeofences}
                  onChange={(e) => { setFilterGeofences(e.target.value); setGeofenceStoppaes(e.target.value); }}
                  multiple
                >
                  {/* menuItems color is being set in style property */}
                  {Object.values(geofences).map((geofence) => (<MenuItem key={geofence.id} value={geofence.id} style={{ color: geofence.attributes.color }}><Typography display="inline" color={geofence.attributes.color}>{geofence.name}</Typography></MenuItem>))}
                </Select>
              </FormControl>
            </div>
          </Popover>
        </div>
        {(selectedItem || mapVisible) && (
          <div className={`${classes.containerMap} ${selectedItem || mapVisible ? classes.show : ''}`}>
            <MapView>
              {/* MapCamera is moving towards selectedItem lngLat */}
              {!mapVisible && <MapCamera key={selectedItem.id} longitude={selectedItem.longitude} latitude={selectedItem.latitude} />}
              <MapGeofence onClick={mapVisible && onClick} routes={routes} />
              <MapMarkers markers={Object.values(stoppages)} />
            </MapView>
          </div>
        )}
        <div className={classes.containerMain}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnAction} />
                <TableCell>
                  <Tooltip placement="top" title={!alphabetic.length ? 'Sort alphabetically' : 'Sort chronologically'}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                      <Button sx={{ mr: '20px' }} size="small" endIcon={<SortByAlphaIcon />} onClick={!alphabetic.length ? arrowDownClick : () => setAlphabetic([])}>
                        <Typography color={!alphabetic.length ? 'blue' : 'secondary'} display="inline">
                          {t('settingsStoppages')}
                        </Typography>
                      </Button>
                      <Badge badgeContent={stoppagesNumber} color="primary" />
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>{t('settingsCoordinates')}</TableCell>
                <TableCell>{t('sharedRoutes')}</TableCell>
                <TableCell className={classes.columnAction} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading ? (
                filteredS.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={classes.columnAction} padding="none">
                      {selectedItem === item ? (
                        <IconButton
                          size="small"
                          onClick={() => setSelectedItem(false)}
                          disabled={mapVisible}
                        >
                          <ShareLocationIcon fontSize="small" color="primary" />
                        </IconButton>
                      ) : (
                        <Tooltip placement="left" title={`See ${item.name} on map`}>
                          <IconButton
                            size="small"
                            onClick={() => setSelectedItem(item)}
                            disabled={mapVisible}
                          >
                            <FmdGoodIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      {`Latitude: ${item.latitude.toFixed(6)}, 
                       Longitude: ${item.longitude.toFixed(6)}`}
                    </TableCell>
                    <TableCell>
                      <Item key={item.id} item={item} />
                    </TableCell>
                    <TableCell className={classes.columnAction} padding="none">
                      <CollectionActionsStoppage
                        itemId={item.id}
                        editPath="/settings/stoppage"
                        endpoint="stoppages"
                        setTimestamp={setTimestamp}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableShimmer columns={5} endAction />
              )}
            </TableBody>
          </Table>
          <CollectionFab editPath="/settings/stoppage" />
        </div>
      </div>
    </PageLayout>
  );
};

export default StoppagesPage;
