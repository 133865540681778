//* creatend a child component 'Item' whose parent component is 'stoppagePage'
import React, { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
//* creating new functional component Item passing 'item' as an argument
const Item = ({ item, fontSize, fontStyle }) => {
  const [geofences, setGeofences] = useState([]);

  useEffect(() => {
    fetch(`/api/geofences?stoppageId=${item.id}`)
      .then((response) => response.json())
      .then((data) => setGeofences(data))
      .catch((error) => {
        throw error;
      });
  }, []);

  return (
    geofences.map((geofence, idx) => (
      <Typography component="span" key={geofence.id} display="inline" color={geofence.attributes.color} fontSize={fontSize} fontStyle={fontStyle}>
        {geofence.name}
        {idx < geofences.length - 1 ? ', ' : ''}
      </Typography>
    ))
  );
};

export default Item;
