import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Badge, Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import { getStatusColor, formatStatus } from '../common/util/formatter';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  blurredText: {
    filter: 'blur(1px)',
    color: 'InactiveBorder',
    textShadow: '0 0 2px rgba(0,0,0,0.5)',
  },
}));

const DevicesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const tanks = Object.values(useSelector((state) => state.tanks.items));

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const totalDevices = items.length;

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        const devices = await response.json();
        //* Sort the devices array alphabetically by name
        devices.sort((a, b) => a.name.localeCompare(b.name));
        setItems(devices);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedDevices']}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Box display="flex" alignItems="center">
                <span style={{ marginRight: '10px' }}>{t('settingsVehicels')}</span>
                <Badge badgeContent={totalDevices} color="primary" style={{ marginLeft: '10px' }} />
              </Box>
            </TableCell>
            <TableCell>{t('deviceStatus')}</TableCell>
            <TableCell>{t('deviceIdentifier')}</TableCell>
            <TableCell>{t('deviceCategory')}</TableCell>
            <TableCell>{t('deviceRegistration')}</TableCell>
            <TableCell>{t('deviceTanktype')}</TableCell>
            <TableCell>{t('devicelastupdate')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {item.speciality === true && (
                    <span style={{ color: 'blue', marginRight: 10, fontSize: '15px' }}>●</span>
                  )}
                  <span style={{ color: item.disabled ? '#999' : '#000', marginRight: 30 }}>
                    {item.name}
                  </span>
                </div>
              </TableCell>
              <TableCell style={{ fontFamily: 'revert' }} className={classes[getStatusColor(item.status)]}>
                {
                  item.status === 'online' || !item.lastUpdate
                    ? formatStatus(item.status, t)
                    : moment(item.lastUpdate).fromNow()
                }
              </TableCell>
              <TableCell>{item.uniqueId}</TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{item.registration}</TableCell>
              <TableCell>{tanks.find((tank) => tank.id === item.tankid)?.name}</TableCell>
              <TableCell>{item.lastUpdate}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions itemId={item.id} editPath="/settings/device" endpoint="devices" setTimestamp={setTimestamp} />
              </TableCell>
            </TableRow>
          )) : (<TableShimmer columns={9} endAction />)}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/device" />
    </PageLayout>
  );
};

export default DevicesPage;
