import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
//* application logo should be replaced here
import svmtsSvg from '../resources/images/svmts.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  logoText: {
    paddingRight: theme.spacing(1),
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('353')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('307')]: {
      fontSize: '9px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
  contributors: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
    marginBottom: theme.spacing(1),
  },
  names: {
    fontSize: '14px',
    marginBottom: theme.spacing(1),
  },
  svg: {
    maxWidth: '100%',
    height: 'auto',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Paper className={classes.paper}>
        <svg height="100" width="250" className={classes.svg}>
          <use xlinkHref={`${svmtsSvg}#img`} />
        </svg>
        <div className={classes.logoText}>Smart Vehicle Monitoring System for Khulna University</div>
        <form className={classes.form}>
          {children}
        </form>
        <div style={{ fontSize: '13px', textAlign: 'center', color: 'rgba(0, 0, 0, 0.6)' }}>
          <div style={{ fontWeight: 'bold' }}>Contributors:</div>
          <div style={{ marginTop: '8px' }}>PI: Aminul Islam</div>
          <div>CO-PI: Atish Dipankar Biswas</div>
          <div style={{ marginTop: '8px' }}>
            <div style={{ fontWeight: 'bold' }}>Research assitants:</div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '4px' }}>
              <div>Abid Shahriar</div>
              <div>Abu Dawod Rahman</div>
              <div>Wasiqul Haque Rafi</div>
              <div>Mahmudul Hasan Abid</div>
            </div>
          </div>
          <div style={{ marginTop: '8px', fontWeight: 'bold', fontSize: '15px' }}>Granted by Khulna University Research Cell, Khulna University (KURC)</div>
        </div>
      </Paper>
    </main>
  );
};

export default LoginLayout;
